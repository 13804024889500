.bgLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #485c66;
  opacity: 0.7;
}
